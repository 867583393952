import './App.css';
import { socket, SocketContext } from './context/socket';
import LaunchIndicator from './components/LaunchIndicator';

function App() {
  return (
    <SocketContext.Provider value={socket}>
      <div className="App">
        <LaunchIndicator />
      </div>
    </SocketContext.Provider>
  );
}

export default App;
