import { useEffect, useState, useContext } from 'react';
import { SocketContext } from '../context/socket';

// Component for displaying launch updates
const LaunchIndicator = () => {
  const socket = useContext(SocketContext);
  const [timestamp, setTimestamp] = useState();

  useEffect(() => {
    socket.on('command_sent', (...args) => {
      console.log('recieved command.');
      const command = args[0];
      if (command.command_name === 'test.command.launch') {
        setTimestamp(command.timestamp);
      }
    });

    socket.on('connect_error', (err) => {
      console.log(`connect_error due to ${err.message}`);
    });

    socket.on('connect', (connection) => {
      console.log('websocket connected');
    });
    socket.on('debug', (message) => {
      if (window.enable_debug) {
        console.debug(message);
      }
    });
    socket.on('*', (...data) => {
      console.log('wildcard');
      console.log(data);
    });

    return () => {
      console.log('disconnecting socket');
      socket.disconnect();
    };
  }, [socket]);

  return (
    <div>
      {!timestamp && (
        <span className="prelaunch">No launch command detected.</span>
      )}
      {timestamp && <span>Launch command detected at {timestamp}</span>}
    </div>
  );
};

export default LaunchIndicator;
